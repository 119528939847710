<template>
  <div class="container-modal-edit">
   <div class="form-new-brand-edit">
      <div class="header-new-brand-edit">
        <p class="background-icon"><span class="exclamation-icon">!</span></p>
        <app-heading level="h6">{{ $t('bvs.change_name_title') }}</app-heading>
        <app-heading level="h7" class="subtitle-modal">{{ $t('bvs.change_name_subtitle') }}</app-heading>
      </div>
      <div class="section-brand-edit">
        <label for="name-edit" class="label-edit">
          <app-heading level="h7" class="title-brand-edit">{{ $t('bvs.name') }}:</app-heading>
          <input type="text" id="name-edit" class="input-brand-edit" placeholder="Name" v-model="nameCustomBrand">
        </label>
      </div>
      <div class="footer-brand-edit">
        <button class="btn-brand-edit" @click="updateBrand()">
          <p v-if="!loading">{{ $t('bvs.change') }}</p>
          <Circle2 v-else class="spinner-social"/>
        </button>
        <button class="btn-brand-edit cancel" @click="closeModalUpload()">Cancel</button>
      </div>
   </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'

export default {
  name: 'ModalEditBrand',
  props: ['brand'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: false,
      nameCustomBrand: ''
    }
  },
  mounted () {
    const modal = document.querySelector('.form-new-brand-edit')
    setTimeout(() => { modal.classList.add('display') }, 100)

    if (this.brand) {
      this.nameCustomBrand = this.brand.customBrand.name
    }
  },
  computed: {
    account () {
      const account = this.$store.getters['account/getAccount']
      return account
    }
  },
  methods: {
    async updateBrand () {
      this.loading = true
      let updated = this.brand
      const nameCustom = this.nameCustomBrand.trim()
      if (this.brand && nameCustom && this.brand.customBrand.name !== nameCustom) {
        [updated] = await socialMediaService.updateCustomBrands([{ _id: this.brand._id, name: nameCustom }])
        setTimeout(() => {
          this.closeModalUpload()
          this.loading = false
          this.$store.commit('account/updateBrandById', updated)
        }, 1500)
      }
    },
    closeModalUpload () {
      const modal = document.querySelector('.form-new-brand-edit')
      modal.classList.remove('display')
      setTimeout(() => { this.$root.$emit('close-brand-action-modals') }, 300)
    }
  }
}
</script>

<style lang="scss">
.container-modal-edit {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000004a;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-new-brand-edit {
    opacity: 0;
    transition: opacity 0.3s;
    user-select: none;
    position: relative;
    width: 25%;
    height: 35%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    .header-new-brand-edit {
      width: 100%;
      height: 50%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .background-icon {
        width: 50px;
        height: 50px;
        background: #ffa50021;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .exclamation-icon {
          width: 50%;
          height: 50%;
          border: 1px solid #efb64d;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 17px;
          font-weight: 500;
          color: #efb64d;
        }
      }
      h6 {
        text-align: center;
        font-weight: 500;
        color: #454548;
        margin: 15px 0 !important;
      }
      .subtitle-modal {
        color: #858585;
        font-size: 13px;
      }
    }
    .section-brand-edit {
      width: 100%;
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      .label-edit {
        width: 90%;
        padding: 0 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .title-brand-edit {
          font-weight: 500 !important;
          color: #454548 !important;
        }
        .input-brand-edit {
          height: 35px;
          outline: none;
          border-radius: 3px;
          padding-left: 5px;
          font-size: 15px;
          color: #464649;
          border: 1px solid #cacaca;
          background: #F0F2F5;
        }
      }
    }
    .footer-brand-edit {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F0F2F5;
      .btn-brand-edit {
        width: 90px;
        height: 30px;
        margin: 0 5px;
        padding: 5px 7px;
        border-radius: 3px;
        background: var(--accent-color);
        color: var(--default-text-color);
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 20px !important;
          height: 20px !important;
          border-width: 2px !important;
          border-color: var(--accent-color) var(--default-text-color) var(--default-text-color) !important;
        }
      }
      .cancel {
        background: #ffffff;
        color: var(--accent-color);
        border: 1px solid #cacaca;
        font-weight: 600;
      }
    }
  }
  .display {
    opacity: 1;
  }
}
</style>
